
  import type { PropType } from 'vue'

  import type { Project } from '@/models/project'

  import { defineComponent } from 'vue'

  import Technology from '@/components/landing/Technology.vue'

  export default defineComponent({
    name: 'WorkRow',
    components: {
      Technology,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
      },
    },
    computed: {
      columns(): [string, string, number][] {
        const epicName: [string, string] = ['epicName', 'Epic']
        const title: [string, string] = ['title', 'Project']
        const technologyIcon: [string, string] = ['technologyIcon', 'Tech']
        const technologiesText: [string, string] = ['technologiesText', 'Tech']
        const technologies: [string, string] = ['technologies', 'Tech']

        let columns: [string, string, number][]
        switch (this.$store.getters['ui/breakpointName']) {
          case 's':
          case 'spb':
            columns = [
              [...epicName, 40],
              [...title, 60],
            ]
            break
          case 'mb':
          case 'mpb':
            columns = [
              [...epicName, 20],
              [...title, 30],
              [...technologiesText, 50],
            ]
            break
          case 'lb':
            columns = [
              [...epicName, 20],
              [...title, 30],
              [...technologies, 50],
            ]
            break
          default: // z
            columns = [
              [...technologyIcon, 10],
              [...epicName, 36], // 40% of 90
              [...title, 54], // 60% of 90
            ]
            break
        }
        return columns
      },
    },
  })
