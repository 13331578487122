
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  import Hello from '@/components/landing/Hello.vue'

  export default defineComponent({
    name: 'Landing',
    components: {
      Hello,
    },
    setup() {
      const store = useStore()
      const isLandingInactive = computed(() => store.getters['ui/isLandingInactive'])

      return {
        isLandingInactive,
      }
    },
  })
