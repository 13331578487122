
  import { defineComponent } from 'vue'
  import { Binary, binComposition } from 'seeelaye'

  export const binaryFn = (): Binary<[], []> => new Binary<[], []>(
    'Intro',
    'intro',
    'Show my handle as ASCII art.',
    [],
    [],
  )

  export default defineComponent({
    name: 'Intro',
    setup() {
      binComposition()
    },
  })
