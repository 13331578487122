
  import { defineComponent } from 'vue'

  import Experience from '@/components/landing/Experience.vue'
  import Work from '@/components/landing/Work.vue'

  export default defineComponent({
    name: 'Resume',
    components: {
      Experience,
      Work,
    },
  })
