
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { useKeypress } from 'vue3-keypress'

  export default defineComponent({
    name: 'Slider',
    props: {
      contents: {
        type: String,
      },
    },
    setup() {
      const store = useStore()
      const router = useRouter()

      const isSliderOpen = computed(() => store.state.ui.isSliderOpen)
      const isLandingInactive = computed(() => store.getters['ui/isLandingInactive'])
      const sliderSize = computed(() => store.getters['ui/sliderSize'])
      const isFullScreen = computed(() => ['s', 'z'].includes(store.getters['ui/breakpointName']))
      const toggleSlider = () => {
        store.commit('ui/setIsSliderOpen', {
          isSliderOpen: !isSliderOpen.value,
        })
      }
      const goHome = () => {
        router.push({ name: 'home' })
      }

      useKeypress({
        keyEvent: 'keydown',
        keyBinds: [
          {
            keyCode: 'esc',
            success: () => {
              if (isLandingInactive.value) {
                goHome()
              }
            },
          },
        ],
      })

      return {
        isSliderOpen,
        isLandingInactive,
        sliderSize,
        isFullScreen,
        toggleSlider,
        goHome,
      }
    },
  })
