
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'Footer',
    setup() {
      const store = useStore()
      const breakpointName = computed(() => store.getters['ui/breakpointName'])

      const isLucky = computed(() => Math.random() >= 0.9)

      return {
        breakpointName,
        isLucky,
      }
    },
  })
