
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useSeeelaye } from 'seeelaye'

  import { tree, epics, orgs } from '@/data/resume'

  import Pane from '@/components/layouts/Pane.vue'
  import Slider from '@/components/layouts/Slider.vue'

  import Landing from '@/components/Landing.vue'
  import Detail from '@/components/Detail.vue'
  import CLI from '@/components/CLI.vue'

  import debounce from 'lodash/debounce'

  export default defineComponent({
    name: 'App',
    components: {
      Pane,
      Slider,

      Landing,
      Detail,
      CLI,
    },
    setup() {
      const store = useStore()
      store.commit('resume/setOrgs', { orgs })
      store.commit('resume/setEpics', { epics })

      const seeelaye = useSeeelaye()
      seeelaye.commit('setTree', { tree })

      const setBreakpoint = () => store.commit('ui/setViewportWidth')
      setBreakpoint()
      window.addEventListener(
        'resize',
        debounce(setBreakpoint, 100),
        false,
      )
      const isTwoPane = computed(() => store.getters['ui/isTwoPane'])
      const sliderContents = computed(() => store.getters['ui/sliderContents'])

      return {
        isTwoPane,
        sliderContents,
      }
    },
  })
