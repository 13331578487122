
  import type { Role } from '@/models/role'

  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { useSeeelaye } from 'seeelaye'

  import ExperienceRow from '@/components/landing/ExperienceRow.vue'

  export default defineComponent({
    name: 'Experience',
    components: {
      ExperienceRow,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const seeelaye = useSeeelaye()

      const roles = computed(() => store.getters['resume/roles'])

      const populateInput = (role: Role) => {
        if (store.state.ui.isSliderOpen && store.getters['ui/sliderContents'] === 'cli') {
          seeelaye.dispatch('executeCmd', {
            rawInput: `cat ${role.node.absolutePath}`,
          })
        } else {
          router.push(role.itemUrl)
        }
      }

      return {
        roles,
        populateInput,
      }
    },
  })
