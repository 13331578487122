
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Icon',
    props: {
      paths: {
        type: Array,
        required: true,
      },
    },
  })
