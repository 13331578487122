
  import { defineComponent } from 'vue'
  import Intro from '@/components/landing/Intro.vue'
  import Resume from '@/components/landing/Resume.vue'
  import Footer from '@/components/landing/Footer.vue'

  export default defineComponent({
    name: 'Hello',
    components: {
      Intro,
      Resume,
      Footer,
    },
  })
