import { render } from "./App.vue?vue&type=template&id=89426e48"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "@/styles/base.css?vue&type=style&index=0&lang=css"
import "@/styles/components.css?vue&type=style&index=1&lang=css"
import "@/styles/utilities.css?vue&type=style&index=2&lang=css"
import "./App.vue?vue&type=style&index=3&id=89426e48&lang=css"
script.render = render

export default script