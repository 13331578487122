
  import type { Project } from '@/models/project'

  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { useSeeelaye } from 'seeelaye'

  import WorkRow from '@/components/landing/WorkRow.vue'

  export default defineComponent({
    name: 'Work',
    components: {
      WorkRow,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const seeelaye = useSeeelaye()

      const projects = computed(() => store.getters['resume/projects'])

      const populateInput = (project: Project) => {
        if (store.state.ui.isSliderOpen && store.getters['ui/sliderContents'] === 'cli') {
          seeelaye.dispatch('executeCmd', {
            rawInput: `cat ${project.node.absolutePath}`,
          })
        } else {
          router.push(project.itemUrl)
        }
      }

      return {
        projects,
        populateInput,
      }
    },
  })
