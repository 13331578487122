
  import { defineComponent } from 'vue'
  import {
    Terminal,
    useSeeelaye,
  } from 'seeelaye'

  export default defineComponent({
    name: 'Pro',
    components: {
      Terminal,
    },
    setup() {
      const seeelaye = useSeeelaye()

      // Run startup commands when terminal is ready
      let index = 0
      const startupCommands = ['intro', 'help']
      const unsubscribe = seeelaye.store.subscribe((mutation) => {
        if (mutation.type.includes('/setIsReady') && mutation.payload.isReady) {
          const command = startupCommands[index]
          if (command) {
            index += 1
            seeelaye.dispatch('executeCmd', { rawInput: command })
          }
          if (index === startupCommands.length) {
            unsubscribe()
          }
        }
      })

      seeelaye.commit('setCurrentNode', {
        currentNode: seeelaye.state.tree,
      })
      seeelaye.commit('setIsReady', { isReady: true })
    },
  })
